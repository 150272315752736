import React, { useState } from "react";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import image1 from "../src/assets/image1.webp";
import image2 from "../src/assets/image2.webp";
import image3 from "../src/assets/image3.webp";
import Bottle from "../src/assets/produs.webp";
import "./App.css";
import Home from "./pages/Home.jsx";
import Amfora from "./assets/umbra.webp";
import Bee from "./assets/bee.webp";
import Cart from "./assets/cart.webp";
import Age from "../src/assets/logo-age.png";
import MyCookieConsent from "./components/MyCookieConsent.jsx";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import "../src/data/i18n.js";
import { changeLanguage } from "i18next";

const App = () => {
  const [ageConfirmed, setAgeConfirmed] = useState(null);

  const { t } = useTranslation(); // Hook pentru a folosi traducerile

  const handleAgeConfirmation = (isOver18) => {
    if (isOver18) {
      setAgeConfirmed(true);
    } else {
      setAgeConfirmed(false);
    }
  };

  if (ageConfirmed === null) {
    return (
      <div className="age-gate">
        <img src={Age} alt="Logo" className="age-logo" />
        <p className="para-age-gate">{t("age_gate_text")}</p>
        <div className="age-buttons">
          <button
            className="age-button-container"
            onClick={() => handleAgeConfirmation(true)}>
            {t("over_18")}
          </button>
          <button
            className="age-button-container"
            onClick={() => handleAgeConfirmation(false)}>
            {t("under_18")}
          </button>
        </div>

        {/* Butoanele pentru schimbarea limbii */}
        <div className="welcome-lang language-switcher ">
          <div
            className="lang-icon flag-icon"
            onClick={() => changeLanguage("ro")}>
            ro
          </div>
          <p className="p-language-wel">|</p>
          <div
            className="lang-icon flag-icon"
            onClick={() => changeLanguage("en")}>
            {" "}
            en
          </div>
        </div>
      </div>
    );
  }

  if (ageConfirmed === false) {
    return (
      <div className="age-restriction">
        <p>{t("age_restricted")}</p>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <Home />
      <ParallaxProvider>
        <div className="content-section">
          <div className="top-container">
            <Parallax className="parallax-container" speed={-12.5}>
              <h3 className="text-on-image">{t("history")}</h3>
            </Parallax>
            <img className="image-header-right" src={image1} alt="history" />
          </div>
          <div className="under-section">
            {" "}
            <img className="image-section" src={Amfora} alt="miedaria" />
            <p className="text-content-p">
              <Trans
                i18nKey="description_history"
                components={{ span: <span className="bolded" /> }}
              />
            </p>
          </div>
        </div>
        <div className="content-section">
          <div className="top-container">
            <Parallax className="parallax-container" speed={-12.5}>
              <h3 className="text-on-image">MODERN</h3>
            </Parallax>
            <img className="image-header-right" src={image2} alt="history" />
          </div>
          <div className="sticla-section under-section">
            <img className="bottle image-section" src={Bottle} alt="miedaria" />
            <div className="under2">
              <p className="text-2">
                <Trans
                  i18nKey="description_modern"
                  components={{ span: <span className="bolded" /> }}
                />
              </p>
              <div className="button-container-modern">
                <h3 className="mied-regina">Mied Regina*</h3>
                <h4 className="cinci-alcol">5% Alc.</h4>
                <a
                  href="https://primitivfoods.ro/produs/bax-12-x-0-33l-mied-ipm-india-pale-mead-copiaza/"
                  target="blank"
                  rel="noopener noreferrer"
                  className="button-comanda">
                  {t("order")}
                  <img
                    className="cart-image"
                    src={Cart}
                    alt="comanda mied online"
                  />
                </a>
              </div>
            </div>
            <img
              className="bottle-2 image-section"
              src={Bottle}
              alt="miedaria"
            />
            <div className="button-container-modern-mobile">
              <h3 className="mied-regina">Mied Regina*</h3>
              <h4 className="cinci-alcol">5% Alc.</h4>
              <a
                href="https://primitivfoods.ro/produs/bax-12-x-0-33l-mied-ipm-india-pale-mead-copiaza/"
                target="blank"
                rel="noopener noreferrer"
                className="button-comanda">
                {t("order")}
                <img
                  className="cart-image"
                  src={Cart}
                  alt="comanda mied online"
                />
              </a>
            </div>
          </div>
          <p className="medalia-aur">
            <b>{t("golden")}</b>
          </p>
        </div>
        <div className="content-section">
          <div className="top-container">
            <Parallax className="parallax-container" speed={-12.5}>
              <h3 className="text-on-image">{t("home")}</h3>
            </Parallax>
            <img className="image-header-right" src={image3} alt="history" />
          </div>
          <div className="under-section under-3-section">
            <div className="under3">
              <p className="text-3">
                <Trans
                  i18nKey="description_home"
                  components={{
                    span: <span className="bolded" />,
                    span1: <span className="waves" />,
                  }}
                />
              </p>
              <img className="image-section-bee" src={Bee} alt="miedaria" />
            </div>
          </div>
        </div>
      </ParallaxProvider>
      <MyCookieConsent />
      <Footer />
    </div>
  );
};

export default App;
