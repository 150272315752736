import React from "react";
import CookieConsent from "react-cookie-consent";
import "../styling/CookieConsentStyles.css"; // Importăm fișierul CSS separat

const MyCookieConsent = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Acceptă toate cookie-urile"
      declineButtonText="Refuză"
      cookieName="userConsent"
      containerClasses="cookie-consent-container" // Adăugăm clasa CSS pentru container
      buttonClasses="cookie-button-accept" // Clasă CSS pentru butonul de accept
      declineButtonClasses="cookie-button-decline" // Clasă CSS pentru butonul de refuz
      expires={150}
      enableDeclineButton
      style={{
        zIndex: "2147483647",
        position: "fixed",
        bottom: "0",
        background: "black",
        left: "0",
        width: "50%",
        right: "0",
      }} /* Inline styles to ensure z-index */
      onAccept={() => {
        console.log("Cookie-urile au fost acceptate.");
      }}
      onDecline={() => {
        console.log("Cookie-urile au fost refuzate.");
      }}>
      <p className="cookie-text">
        Acest site folosește cookie-uri pentru a îmbunătăți experiența
        utilizatorului și pentru a analiza traficul. Accesând acest site,
        sunteți de acord cu utilizarea cookie-urilor. Acest site este destinat
        doar persoanelor peste 18 ani.
      </p>
    </CookieConsent>
  );
};

export default MyCookieConsent;
