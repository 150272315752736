import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ro: {
    translation: {
      welcome: "Bine ați venit!",
      headerh1: "Băuturi amețitoare",
      headerh2: "din miere de albine.",
      history: "ISTORIA",
      modern: "MODERN",
      home: "ACASĂ",
      locations: "Locații Partenere",
      order: "Comandă",
      description_history:
        "<span class='bolded'> Miedul</span>, este probabil cea mai veche băutură alcoolică din lume, rezultată din fermentarea mierii de albine. Cu o tărie apropiată de cea a vinului, era considerată băutura zeilor în mitologia greacă, iar acum este populară în special în țările nordice.",
      description_modern:
        "<span class='bolded'> Noi</span> facem miedul într-un stil modern, lejer, cu alcoolemie mai mică, numai bun pentru momente de socializare. Are un gust demidulce și e ușor înțepător de la bulele fine rezultate din fermentarea naturală.",
      description_home:
        "<span class='bolded'> Din 2021</span> producem această băutură amețitoare, cum ne place să îi spunem, pe <span1 className='waves'> ~~~ </span1>Valea Buzăului <span1 classclassName='waves'> ~~~ </span1>în apropierea Geoparcului Ținutul Buzăului, parte dinpatrimoniul internațional UNESCO. Folosim cea mai bună miere provenită de la apicultori din zonă, care se ocupă cu creșterea albinelor de mai multe generații. <br /> <br /> Printr-o singură sticlă de mied cumpărată, poți ajuta la polenizarea unui milion de flori. Astfel, miedul nu este doar o băutură tare bună, dar este și prietenoasă cu mediul. <span class='bolded'> Miedul iubește mediul.</span>",
      golden:
        "* În 2024 a luat medalia de aur la concursul internațional Mead Madness Cup din Polonia, una dintre cele mai prestigioase competiții din lumea miedului.",
      age_gate_text:
        "Dacă vrei să intri în lumea amețitoare a miedului trebuie să ai peste 18 ani",
      over_18: "AM PESTE 18 ANI",
      under_18: "NU AM PESTE 18 ANI",
      age_restricted: "Ne pare rău, din păcate trebuie să ai peste 18 ani.",
    },
  },

  en: {
    translation: {
      welcome: "Welcome!",
      headerh1: "Dizzying drinks",
      headerh2: "from honey bee.",
      history: "HISTORY",
      modern: "MODERN",
      home: "HOME",
      locations: "Partner Locations",
      order: "Order",
      description_history:
        "<span class='bolded'> Mead</span>, likely the world’s oldest alcoholic beverage, is created through the fermentation of honey. Similar in strength to wine, it holds a special place in Nordic traditions and was revered as the drink of the gods in Greek mythology.",
      description_modern:
        "<span class='bolded'> Our</span> mead is crafted in a modern, approachable style, featuring a lower alcohol content that makes it perfect for socializing. It offers a semi-sweet taste with a subtle effervescence from natural fermentation.",
      description_home:
        "<span class='bolded'> Our</span> small-scale production takes place in the picturesque Buzaului Valley, close to the Buzaului State Geopark, a UNESCO World Heritage site. We source the finest honey from local beekeepers who have honed their craft over generations. By purchasing a single bottle of our mead, you support the pollination of a million flowers, making it an eco-friendly choice.",
      golden:
        "* This year he won the gold medal at the international Mead Madness Cup in Poland, one of the most prestigious competitions in the world of mead",
      age_gate_text:
        "If you want to enter the enchanting world of mead, you must be over 18 years old",
      over_18: "I AM OVER 18",
      under_18: "I AM UNDER 18",
      age_restricted: "Sorry, you must be over 18 years old.",
    },
  },
};

i18n
  .use(initReactI18next) // Integrează cu React
  .init({
    resources,
    lng: "ro", // Setează limba implicită la română
    fallbackLng: "ro", // Folosește româna dacă nu e detectată limba
    interpolation: {
      escapeValue: false, // React deja face asta
    },
  });

export default i18n;
