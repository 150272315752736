import React from "react";
import "../styling/modal.css";

const Modal = ({ showModal, onClose }) => {
  if (!showModal) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Termeni și Condiții</h2>
        <p>
          Prezentul document prin continutul sau pune la dispozitia tertilor
          utilizatori Regulamentul privind termenii si conditiile de utilizare a
          site-ului Primitivfoods.ro.
        </p>
        <p>
          Site-ul Primitivfoods.ro este operat de societatea Scott Max Green
          S.R.L., cu sediul in Patarlagele, str. Tudor Vladimirescu, nr 3, jud
          Buzau inscrisa in Registrul Comertului cu numarul J10/555/2015.
          Utilizarea site-ului (incluzand accesul, navigarea si cumpararea
          produselor de pe acest site) constituie un acord implicit de
          respectare a termenilor si conditiilor enuntate in cuprinsul
          prezentului document cu toate efectele si consecintele ce decurg din
          aceasta.
        </p>
        <p>
          Administratorul site-ului primitivfoods.ro isi rezerva dreptul de a
          modifica in orice moment continutul acestui acord, fara notificarea
          prealabila a persoanelor care il utilizeaza, denumite in continuare
          „Utilizatori”. Utilizatorii vor avea acces permanent la termenii si
          conditiile de utilizare a serviciilor, pentru a le putea consulta in
          orice moment.
        </p>
        <h3>Conținutul site-ului și drepturile de proprietate intelectuală</h3>
        <p>
          Continutul acestui site nu poate fi utilizat, reprodus, distribuit,
          transmis, expus, in alte scopuri decat cele expres si legal permise.
          Extragerea oricaror informatii urmata de orice utilizare in scop
          comercial care depaseste sfera copiei private reglementate de lege sau
          pentru vanzare ori licentiere si fara a avea in prealabil un
          consimtamant scris al titularilor drepturilor de proprietate
          constituie o incalcare a termenilor si conditiilor.
        </p>
        <p>
          Sunteti de asemenea de acord sa nu afectati si interferati in vreun
          fel cu elementele de securitate ale site-ului, cu elementele care
          previn sau restrictioneaza utilizarea, copierea unui continut sau
          elemente care intaresc limitele de utilizare a siteului sau a
          continutului acestuia.
        </p>
        <h3>Dreptul consumatorilor de denuntare unilaterala a contractului</h3>
        <p>
          Va rugam sa va asigurati ca ati verificat specificatiile produsului si
          compatibilitatea acestuia prin vizitarea paginii producatorului
          inainte de a-l cumpara. Raporturile comerciale dintre cumparator si
          S.C. Scott Max Green S.R.L. sunt reglementate oficial de Ordonanta
          Guvernului 130/2000, privind protectia consumatorilor la incheierea si
          executarea contractelor la distanta.
        </p>
        <p>
          Consumatorul are dreptul sa notifice in scris comerciantului ca
          renunta la cumparare, fara penalitati si fara invocarea unui motiv, in
          termen de 10 zile lucratoare de la primirea produsului sau, in cazul
          prestarilor de servicii, de la incheierea contractului.
        </p>
        <p>
          Administratorul S.C. Scott Max Green S.R.L.( primitivfoods.ro) face
          eforturi permanente pentru a pastra acuratetea informatiilor de pe
          site, insa uneori acestea pot contine inadvertente (specificatiile sau
          pretul produselor modificate de catre producator fara preaviz sau
          viciate de erori de operare). Atentie: fotografiiile au caracter
          informativ si pot contine accesorii care nu sunt incluse in pachetele
          standard.
        </p>
        <h3>Frauda</h3>
        <p>
          In scopul accesarii si utilizarii anumitor sectiuni ale website-ului
          poate fi necesara crearea unui cont personal. Prin prezenta declarati
          ca va asumati integral responsabilitatea pentru toate si oricare
          dintre activitatile realizate prin intermediul contului pe care il
          deschideti pe website si, in consecinta, va sfatuim sa asigurati
          securitatea parolei contului sau a altor date de acces. In cazul in
          care securitatea contului pe care il detineti este compromisa, trebuie
          sa anuntati imediat administratorul site-ului. S.C. Scott Max Green
          S.R.L.( primitivfoods.ro) nu este responsabil pentru daunele care va
          sunt cauzate sau care sunt cauzate tertilor de orice fel, prin
          utilizarea neautorizata a contului.
        </p>
        <h3>Drept aplicabil</h3>
        <p>
          Prin folosirea site-ului primitivfoods.ro, utilizatorul se declara de
          acord asupra faptului ca legile romane vor guverna Termenii si
          conditiile de utilizare si orice disputa de orice fel care ar putea sa
          apara intre utilizatori si administratorii S.C. Scott Max Green
          S.R.L.( primitivfoods.ro) sau asociatii/partenerii/afiliatii acestuia.
          In cazul unor eventuale conflicte se va incerca mai intai rezolvarea
          acestora pe cale amiabila, iar daca rezolvarea pe cale amiabila nu va
          fi posibila, conflictul va fi solutionat in instanta, in conformitate
          cu legile romane in vigoare.
        </p>
        <h3>Disponibilitatea serviciului</h3>
        <p>
          Administratorul S.C. Scott Max Green S.R.L.( primitivfoods.ro) isi
          rezerva dreptul de a modifica structura si interfata oricarei pagini
          sau subpagini a site-ului primitivfoods.ro in orice moment si la orice
          interval de timp liber ales, avand dreptul de a intrerupe temporar sau
          permanent, partial sau in totalitate serviciile puse la dispozitia
          publicului prin intermediul acestui website fara vreo notificare
          prealabila individuala sau generala.
        </p>
        <p>
          Daca aveti intrebari sau nelamuriri in legatura cu acesti termeni de
          utilizare, nu ezitati sa ne contactati prin intermediul formularului
          dedicat de contact sau la adresa de email negot@primitivfoods.ro.
        </p>
        <button className="close-button" onClick={onClose}>
          Închide
        </button>
      </div>
    </div>
  );
};

export default Modal;
