import React from "react";
import HeaderImage from "../assets/home.webp"; // Updated the import to reflect an image
import "../styling/Home.css";
import Cart from "../assets/cart.webp";
import { t } from "i18next";

const Home = () => {
  return (
    <div id="home" className="home-container">
      <img
        className="header-image" // Changed the className from video-header to header-image
        src={HeaderImage}
        alt="Header"
      />
      <div className="header">
        <h1 className="header-h1">{t("headerh1")}</h1>
        <h2 className="header-h2">{t("headerh2")}</h2>
        <a
          href="https://primitivfoods.ro/produs/bax-12-x-0-33l-mied-ipm-india-pale-mead-copiaza/"
          target="_blank"
          rel="noopener noreferrer"
          className="button-comanda">
          {t("order")}
          <img className="cart-image" src={Cart} alt="comanda mied online" />
        </a>
      </div>
    </div>
  );
};

export default Home;
