import React from "react";
import "../styling/navbar.css";
import Logo from "../assets/logo.webp";
import Locations from "../assets/pin.svg";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const scrollToHome = () => {
    const homeElement = document.getElementById("home");
    if (homeElement) {
      homeElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="navbar-container">
      <img
        className="logo-image"
        src={Logo}
        alt="miedaria"
        onClick={scrollToHome} /* Add the onClick event here */
        style={{
          cursor: "pointer",
        }} /* Optional: Change the cursor to indicate it's clickable */
      />
      <div className="loc-lang">
        <div className="language-switcher-nav">
          <div className="flag-icon-nav" onClick={() => changeLanguage("ro")}>
            ro
          </div>
          <p className="p-language">|</p>
          <div className="flag-icon-nav" onClick={() => changeLanguage("en")}>
            en
          </div>
        </div>
        <button className="location-store">
          <a
            className="anchor-loc"
            href="https://www.google.com/maps/@45.899594,22.2692252,7z/data=!4m3!11m2!2stPaQueYQTbeF6wjBBx_48A!3e3!5m1!1e2?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D"
            target="blank">
            <img className="location-svg" src={Locations} alt="" />

            <span className="location">{t("locations")}</span>
          </a>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
